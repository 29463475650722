<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/03-5商情诊断管理分析.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">商情诊断管理分析</div>
            <div class="mobile-head-explain-content">中国某知名调味品生产公司</div>
        </div>
        <div class="description">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;">
            <el-collapse-transition>
                <div v-if="showDescription">
                    <div class="description-box">客户公司在业务快速发展的同时，非常重视数据方面的投入。数据仓库已实施上线多年，虽然为业务扩张提
                供了有力的数据支持，但仍面临诸多挑战。通过商情诊断管理分析项目，并借助解决方案中针对产品、客户、区域的销售分析体系模板，业务部门结合
                企业自身情况建立了分析指标。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-box1">
                <div class="title1">
                    市场/销售数据难以支持精准决策
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    数据时效性无法满足业务的需求
                </div>
            </div>
            <div class="ware-box1">
                <div class="title1">
                    数据质量需要进行改进
                </div>
            </div>
            <div class="ware-box1" style="margin-bottom:5rem">
                <div class="title1">
                    数据服务成本较高
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-4销售执行.svg"/>
                    <div>
                        <div class="title1">销售执行分析模块  </div>
                        <div class="title2">轻松、方便地创建或修改主数据。</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-4销售结构.svg"/>
                    <div>
                        <div class="title1">销售结构分析模块</div>
                        <div class="title2">平台可针对各个产品、各个客户、各个区域销售情况进行深入分析</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/selected/03-2-4营销活动.svg"/>
                    <div>
                        <div class="title1">营销活动ROI分析模块</div>
                        <div class="title2">平台提供了收入、成本、收益率分析功能，帮助客户及时掌握各营销活动盈利情况</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-title" style="display:flex;margin-top: 3rem;"><div class="purchase-doit">●</div><div class="special-style">平台帮助管理层与销售部门全面了解业务；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">营销与渠道分析应用达到毫秒级的数据分析响应能力；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">在指标框架指导下，对指标进行了标准化和细化描述，统一理解和计算逻辑；</div></div>
            <div class="purchase-title" style="display:flex;"><div class="purchase-doit">●</div><div class="special-style">ETL整体执行效率较线下的数据仓库提升 <div class="title-6"> 6 </div>倍；</div></div>
            <div class="purchase-title" style="display:flex;margin-bottom: 5rem;"><div class="purchase-doit">●</div><div class="special-style">相比使用本地IDC，使用Amazon降低总体拥有成本（TCO）50%。</div></div>
                
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    
    .card{
        width: 52.8rem;
        height: 24rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 5rem 0 0 2rem;
        }
        .title1{
            // height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 4rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            // height: 7.2rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 1.5rem 0 0 2.1rem;
        }
    }
}
.special-style{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
    display: flex;
    .title-6{
        text-align: center;
        margin: auto;
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #ED5847;
        line-height: 3rem;
        margin-top: 0rem;
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;

    margin-top: 2rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #ED5847;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
</style>